#app {
  .footer {
    margin-top: 10px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: white;
    font-size: 10px;

    p {
      margin: 2px;
    }

    a {
      text-decoration: none;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }

    .first-level {
      display: flex;
    }

    .panel {
      margin: 10px;
    }

    hr {
      width: 80%;
      background-color: white;
    }
  }
}
