body {
  margin: 0;
}

#app {
  margin: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(to right top, #773389, #6d59aa, #647ac2, #6598d2, #78b4dc);

  font-family: "Helvetica Neue Light";
  h1, h2, h3 {
    font-family: kiona;
    font-weight: normal;
  }

  color: $main-font-color;
  a {
    color: $main-font-color;
  }

  & > .admin-container {
    min-height: 100vh;

    display: flex;
    flex-direction: column;

    & > .admin-content-container,
    & > header {
      display: flex;
      flex-direction: row;

      & > #aside-nav,
      & > #site-logo {
        width: $left-menu-nav-width;
        background: $gray-darker;
        color: $gray-lightest;

        & > .nav-item {
          background: $gray-darker;
          color: $gray-lightest;
        }
      }

      & > #header-nav,
      & > main {
        flex: 1 0 auto;
      }

      & > main {
        flex-basis: 0;
        width: 0; /* to make sure the main container will never be larger than it should */
        overflow: auto;
      }
    }

    & > .admin-content-container {
      flex: 1 0 auto;
    }
  }
}

/* Hack IE :( */
@media all and (-ms-high-contrast: none) {
  #app & > .admin-container {
    height: 100vh;
  }
}

@media screen and (max-width: 768px) {
  #app {
    overflow: auto;
    height: 100%;
  }
}

@media print {
  -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
  color-adjust: exact !important;
  #app {
    overflow: auto;
    height: 100%;
  }
}
