$base-font-size: 14px;

$left-menu-nav-width: 16rem;

/* bootstrap override */
$enable-flex: true;

$brand-primary: #773389;

$gray-darker: #37506B;
$gray-dark: lighten($gray-darker, 20%);
$gray: lighten($gray-darker, 30%);
$gray-light: #818a91;
$gray-lighter: #eceeef;
$gray-lightest: #f7f7f9;

$main-font-color: #686e73;

$blue: rgb(120, 180, 220);

$red-danger: #d9534f;
$green-success: #2eb72f;

$nav-tabs-active-link-hover-bg: #ffffff;

$body-bg: $gray-lighter;

$headings-font-weight: 300;

$font-size-h1: 1.6rem;
$font-size-h2: 1.3rem;
$font-size-h3: 1.15rem;
$font-size-h4: 1.1rem;
$font-size-h5: 1.05rem;
$font-size-h6: 1rem;

$log-trace: #3CBCC3;
$log-info: #0275d8;
$log-debug: #5cb85c;
$log-error: #d9534f;
$log-warn: #f0ad4e;
$log-off: #777777;

$panel-radius: 15px;

@font-face {
  font-family: "kiona";
  src: url("kiona.ttf");
}

@font-face {
  font-family: "Helvetica Neue Light";
  font-weight: normal;
  src: url("helveticaneue-light-001.ttf");
}
@font-face {
  font-family: "Helvetica Neue Light";
  font-weight: bold;
  src: url("HelveticaNeueLTStd-Bd.otf");
}
