@mixin button-container() {
  button {
    margin-right: 5px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.button-container {
  @include button-container();
}

.top-btn {
  position: absolute;
  right: 25px;
  bottom: 100px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $brand-primary;
  border-radius: 5px;
  &:hover {
    background-color: lighten($brand-primary, 10%);
  }
  i {
    font-size: 20px;
    color: white;
  }
}
