#app {

  .trombinoscope {
    align-self: center;
    justify-self: center;
    width: 95%;

    .instance-logo {
      width: unquote("max(102mm, 100%)");
      height: 25mm;

      img {
        width: 100%;
      }
    }

    .trombinoscope-body {
      display: flex;

      h1 {
        font-size: 1.75em;
      }

      p {
        font-size: 1.25em;

        &.text-capitalize {
          text-transform: capitalize;
        }
      }

      .text-underline {
        text-decoration: underline;
        text-decoration-color: $gray-dark;
      }


      .trombinoscope-aside {
        background-color: white;
        min-width: 370px;
        border-bottom-left-radius: $panel-radius;

        .trombinoscope-menu {
          margin-top: 70px;
          font-size: 14px;
          display: flex;
          flex-direction: column;

          .trombinoscope-link {
            display: flex;
            align-items: center;
            height: 10px;
            margin-top: 5px;
            max-width: 70%;
            padding: 10px;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            color: white;
            text-decoration: none;

            &.selected {
              max-width: 100%;
            }

            &.primary {
              background-color: $blue;
            }

            &.secondary {
              background-color: lighten($brand-primary, 15%);
            }

            &.tertiary {
              background-color: $brand-primary;
            }

          }
        }
      }

      .trombinoscope-instance {
        background-color: white;
        border-radius: 0 $panel-radius $panel-radius 0;
        padding: 5px;
        flex-grow: 2;

        .trombinoscope-content {
          overflow-x: hidden;
          overflow-y: scroll;
          max-height: 65vh;

          h2::first-letter {
            font-size: 1.3em;
          }
        }

        .instance-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          a {
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          i {
            margin-right: 5px;
          }

          p {
            margin: 0;
          }
        }

        .instance-details {
          display: flex;
          flex-direction: column;
          align-self: center;
        }

        .tile-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .tile {
            z-index: 2;
            display: flex;
            margin-bottom: 10px;
            margin-left: 10px;

            i {
              margin-right: 5px;
            }

            a {
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }

            .tile-background {
              box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
              transition: left 0.5s ease-in 0s, z-index 0s 0.5s, width 0.5s ease-in 0s, height 0.5s ease-in 0s;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -2;
              background-color: white;
              border-radius: 10px 10px 10px 10px;
              width: 100%;
              height: 100%;
            }

            .tile-info {
              transition: transform 0.5s ease-in 0s, z-index 0s 0.5s, width 0.5s ease-in 0s;
              visibility: hidden;
              position: absolute;
              height: 95%;
              width: calc(100% - 10px);
              z-index: -1;
              top: 0;
              left: 0;
              padding: 5px;
              font-size: .8em;

              p {
                margin: 0;
                padding: 5px 0 5px 0;

                &.low-profile {
                  padding: 1px 0 3px 0;
                }
                &.purple-text {
                  font-family: kiona;
                  color: $brand-primary;
                  font-size: 1.10em;
                }
              }

              .section {
                .section-title {
                  font-weight: bold;
                  padding: 5px 0 5px 0;
                }

                border-bottom: solid 1px $gray-lighter;
              }
            }

            .tile-membre {
              position: relative;

              &:hover {
                cursor: pointer;
              }

              .membre-photo {
                min-width: 250px;
                min-height: 250px;
                background-size: cover;
                background-color: $brand-primary;
                border-radius: 10px 10px 0 0;

                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 100px;
                color: white;
              }

              .membre-info {
                font-size: 12px;
                background-color: white;
                padding: 5px 0 5px 5px;
                border-radius: 0 0 10px 10px;
                height: 45px;
                overflow: auto;

                p {
                  margin: 0;
                }
              }

              .membre-contact {
                transition: left 0.5s ease-in 0s, transform 0.5s ease-in 0s, z-index 0s 0.5s, width 0.5s ease-in 0s;
                border-top: solid 1px $gray-lighter;
                box-sizing: border-box;
                background-color: white;
                font-size: 12px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                min-height: 40px;
                border-radius: 0 0 10px 10px;
                visibility: hidden;
                overflow: hidden;
                z-index: -1;
                text-align: center;

                p {
                  margin: 0;
                  text-transform: lowercase;
                }

                .no-wrap {
                  white-space: nowrap;
                }
              }
            }

            &.animated {
              z-index: 4;

              .tile-info {
                visibility: visible;
                overflow: hidden;
              }

              &.show-contact {
                .membre-contact {
                  visibility: visible;
                }
              }
            }

            &.show {
              z-index: 4;

              &.animated {
                z-index: 8;
              }

              .tile-membre {
                .tile-background {
                  width: calc(200% + 10px);
                }

                .membre-photo {
                  border-radius: 10px 0 0 0;
                }

                .tile-info {
                  visibility: visible;
                  width: 100%;
                  overflow-y: auto;
                  transform: translate(calc(100% - 10px), 0);
                }

              }

              &.show-contact {
                .tile-background {
                  height: calc(100% + 40px);
                }

                .membre-contact {
                  visibility: visible;
                  width: calc(200% + 10px);
                  transform: translate(0, 45px);
                }
              }

              &.left {
                .tile-membre {
                  .tile-background {
                    left: calc(-100% - 10px);
                  }
                  .membre-photo {
                    border-radius: 0 10px 0 0;
                  }

                  .membre-info {
                    border-radius: 0 0 10px 0;
                  }

                  .tile-info {
                    border-radius: 10px 0 0 10px;
                    transform: translate(-100%, 0);
                  }
                  .membre-contact {
                    left: calc(-100% - 10px);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  #app {
    .trombinoscope {
      .header {
        width: 90%;

        .header-tab {
          width: 90%;
          min-width: 0;
          max-width: 360px;
        }
        img {
          width: 100%;
        }
      }
      .trombinoscope-body {
        display: flex;

        .trombinoscope-aside {
          display: none;
        }

        .trombinoscope-instance {
          border-radius: 0 $panel-radius $panel-radius $panel-radius;

          .instance-header {
            align-content: flex-start;
            flex-direction: column;
            font-size: 12px;

            .instance-details {
              align-self: flex-start;
            }
          }

          .trombinoscope-content {
            max-height: 100%;
          }

          .tile-container {
            .tile {
              margin-bottom: 10px;
              margin-left: 10px;
              width: 43vw;

              .tile-membre {
                .tile-info {
                  overflow-y: hidden;
                }

                .membre-photo {
                  min-width: 43vw;
                  min-height: 43vw;
                }

                .membre-info {
                  font-size: 10px;
                  height: 51px;
                }
              }

              &.show {
                .tile-membre {
                  .tile-info {
                    transform: translateX(calc(100% - 10px));
                  }
                }

                &.left {
                  .tile-membre {
                    .tile-info {
                      border-radius: 10px 0 0 10px;
                      transform: translateX(-100%);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 600px) {
  #app {
    height: 100%;

    #main-content {
    height: auto;
  }
  }
}
