@mixin flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@keyframes showProgress {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.questionnaire-main-content {
  @include flex-column;
  width: 95%;
  height: 90%;
  justify-self: center;
  overflow-y: auto;
  background-color: white;
  border-radius: $panel-radius;
  flex-shrink: 0;
  min-height: inherit;

  &-parent {
    height: 100% !important;
    min-height: 85vh;
    padding-top: 3vh;
  }
  .header-container {
    display: flex;
    width: 100%;
    justify-content: center;
    .ordre-logo {
      min-height: 100px;
      height: 10vh;
      max-height: 10em;
      margin: 0.5% 1% 0.5% 0.5%;
      object-fit: contain;

    }
  }
  .main-header {
    @include flex-column;
    flex-grow: 1;

    h1 {
      font-size: 1.75em;
      margin-bottom: 0;
    }
  }

  .questionnaire-form {
    @include flex-column;
    width: 100%;
    flex: 1 0;

    .required {
      color: $red-danger;
    }

    .questionnaire-content {
      @include flex-column;
      width: 60%;
      flex-shrink: 0;
      padding-bottom: 50px;

      .step-shower {
        width: 70%;
        position: absolute;
        z-index: 999;
        background-color: #fff;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 40%;
        }

        &.fixed {
          position: fixed;
          top: 0;
          padding-top: 6px;
        }

        .progress-bar {
          width: 100%;
          height: 1.5em;
          border-radius: .25rem;
          overflow: hidden;
          background-color: $gray-lighter;
          margin-top: .25rem;
          margin-bottom: 1rem;

          .progress-bar-value {
            background-color: $brand-primary;
            height: inherit;
            transition: width .3s ease-in-out;
          }
        }
      }

      .step-finished {
        padding: 0 .2em;
        margin-bottom: .5em;
        color: $green-success;
        border-radius: .25rem;
        border: solid 1px lighten($green-success, 40%);
        box-shadow: 0 3px 7px 0 rgb(224, 224, 224);
        text-align: center;
        i {
          margin-right: .5em;
        }
      }

      .avant-propos-container {
        @include flex-column;
        margin-top: 5em;
        padding-bottom: 1em;
        width: inherit;
        border-top: solid;
        border-bottom: solid;

        &.no-border {
          border: 0;
        }
      }
      .page-form-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-shrink: 0;
        width: 100%;

        .questionnaire-question {
          flex-grow: 0;
          flex-basis: 40%;
          margin: 1rem 0;
          word-break: normal;
          animation: .5s ease-out 0s 1 showProgress;
          transition: all;

          &--information {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: $brand-primary;
            border: 1px solid $brand-primary;
            border-radius: .25rem;
            padding: .75rem 1.25rem .75rem 0;
            margin-bottom: 1rem;

            i {
              padding-left: .6rem;
              padding-right: .6rem;
            }
          }

          .base-label-input-coreoz {
            width: 100%;
          }

         .radio-component {
            width: fit-content;
          }
        }
        input:disabled {
          color: $gray-dark;
        }
      }
    }

    .page-title {
      border-top: solid;
      border-bottom: solid;
      margin-top: 55px;

      h2 {
        color: $brand-primary;
        font-weight: 700 !important;
        font-size: 1.5em;
      }
    }

    .questionnaire-buttons {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      bottom: 10px;
      padding-top: 1px;
      &.fixed {
        position: fixed;
        bottom: 0;
        padding-bottom: 6px;
      }
      .btn {
        background-color: $brand-primary;
        color: white;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        border-radius: .25rem;
        margin-bottom: .375rem;

        &.btn-reversed {
          background-color: transparent;
          color: $brand-primary;
          border: $brand-primary solid 1px;
        }
      }

      .btn:nth-child(1n+1) {
        margin-left: .5rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .header-container {
      flex-direction: column;
      align-items: center;
      .ordre-logo {
        height: 20%;
        width: 40%;
        margin-bottom: 3%;
        margin-top: 3%;

        &.logo-questionnaire {
          width: 30%;
        }
      }
    }
    .questionnaire-content {
      width: 90% !important;
    }
    .main-header {
      text-align: center;
    }
    .base-container-input-coreoz, .material-container-input-coreoz {
      flex-basis: 100% !important;
    }
  }
}
