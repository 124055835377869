.radios-container {
  display: flex;
  flex-direction: column;

  .radio {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    line-height: 20px;
    font-size: 14px;
    flex-shrink: 0;

    .error {
      color: $red-danger;
      margin-left: 1rem;
    }
    .label {
      position: relative;
      display: block;
      float: left;
      margin-right: 10px;
      width: 1rem;
      height: 1rem;
      border: 2px solid $brand-primary;
      border-radius: 100%;
      -webkit-tap-highlight-color: transparent;
      flex-shrink: 0;

      &.checkbox {
        border-radius: 15%;

        &:after {
          border: solid $brand-primary;
          border-width: 0 3px 3px 0;
          border-radius: 0;
          background: transparent;
          top: 15%;
          left: 30%;
          width: 20%;
          height: 40%;
        }
      }

      &.radio:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0);
        width: 65%;
        height: 65%;
        border-radius: 100%;
        background: $brand-primary;
      }
      &:after {
        content: '';
        position: absolute;
        transform: scale(0);
        transition: all .2s ease;
        opacity: .08;
        pointer-events: none;
      }

      &:hover {
        .label:after{
          transform: scale(3.6);
        }
      }
    }
    input:checked + .label {
      border-color: $brand-primary;
      &.checkbox::after {
        transform: scale(1) rotate(45deg);
      }
      &:after {
        transition: all .2s cubic-bezier(.35,.9,.4,.9);
        opacity: 1;
      }
    }

    input[disabled] + .label {
      opacity: 0.7;
    }
  }
}
