.header {
  position: relative;
  width: 100%;
  display: flex;
  font-size: 12px;
  margin-top: 20px;
  .header-tab {
    display: flex;
    cursor: pointer;
    position: relative;
    min-width: 360px;
    text-transform: capitalize;
    color: white;
    text-decoration: none;
    border-right: 1px solid white;
    background-color: white;
    border-radius: $panel-radius $panel-radius 0 0;
    padding: 5px;
    img {
      width: 350px;
    }
    &:hover {
      text-decoration: underline;
    }
    &:after{
      content: '';
      position: absolute;
      top: 30%;
      right: 5px;
      border-top: 5px solid #000;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }
  }
  .header-select {
    cursor: pointer;
    visibility: hidden;
    position: absolute;
    top: 100%;
    background-color: white;
    text-transform: capitalize;

    &.show {
      padding: 5px;
      visibility: visible;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
  }

}
